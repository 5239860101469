import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from "./config/router";
import VueMeta from 'vue-meta';
import VueMasonry from 'vue-masonry-css';
import VueResource from "vue-resource";
import VueSilentbox from 'vue-silentbox'

import 'bootstrap';
import "@fontsource/tomorrow/300.css";
import "@fontsource/tomorrow/400.css";
import "@fontsource/tomorrow/700.css";
import "@/css/style.scss";

Vue.config.productionTip = false

Vue.use(VueResource);
Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.use(VueMasonry);
Vue.use(VueSilentbox)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


/* CREATE BUS FOR EVENT COMMUNICATION
------------------------------------------------------------------------------------------- */
export const bus = new Vue()


/* PLUGINS (Global Functions)
------------------------------------------------------------------------------------------- */

const encodeURL = {
  install(Vue) {
    Vue.prototype.encodeURL = (url) => {
      var urlfixed = url.replace(/\s+/g, '-').toLowerCase();
      return urlfixed;
    }
  },
}
Vue.use(encodeURL)


const convertLineBreaks = {
  install(Vue) {
    Vue.prototype.convertLineBreaks = (content) => {
      return content.replace(/\n/g, '<br />');
    }
  },
}
Vue.use(convertLineBreaks)


const removeSpaces = {
  install(Vue) {
    Vue.prototype.removeSpaces = (content) => {
      return content.replace(/\s/g, '');
    }
  },
}
Vue.use(removeSpaces)

/*
const firstWordBold = {
  install(Vue) {
    Vue.firstWordBold = (content) => {
			var text = content.trim().split(" ");
			var first = text.shift();
			return "<b>"+first+"</b> " + text.join(" ");
    }
  },
}
Vue.use(firstWordBold)
*/
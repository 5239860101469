import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from "../views/inicio.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "Inicio",
    component: Inicio
  },
  {
    path: '/quemsomos',
    name: 'About',
    component: () => import(/* webpackChunkName: "quemsomos" */ '../views/quemsomos.vue')
  },
  {
    path: "/servicos/:id/:slug",
    name: "Servicos",
    component: () => import(/* webpackChunkName: "servicos" */ "../views/servicos.vue")
  },
  {
    path: "/portfolio/:slug",
    name: "Portfolio",
    component: () => import(/* webpackChunkName: "portfolio" */ "../views/portfolio.vue")
  },
  {
    path: "/contactos",
    name: "Contactos",
    component: () => import(/* webpackChunkName: "contactos" */ "../views/contactos.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router

<template>
	<footer id="footer" class="container">

		<div v-if="!isContactos" class="row mt120 text-center" ref="pedirOrcamento">
			<div class="col-12">
				<h6>Peça já um <b class="text-primary">Orçamento</b></h6>
				<p>Diga-nos o que necessita e nós iremos encontrar a melhor solução para si.</p>
				<a href="mailto:geral@estoresrc.com?subject=Pedido de Orçamento RCF Estores" class="btn btn-outline-primary mt10">Pedir orçamento</a>
			</div>
		</div>

		<div class="row mt120 mb50">
			<div class="col-6 offset-3 col-md-4 offset-md-0 col-xl-2 text-center order-md-2">
				<a href="#" class="back-top">
					<div class="texto-pop">
						Voltar ao topo
					</div>
					<img src="@/images/back-top.svg" style="height:55px;">
				</a>
			</div>
			<div class="col-12 col-md-4 col-xl-5 text-center text-md-left order-md-1">
				<small><span class="text-primary">©2020</span> Todos os direitos reservados</small>
			</div>
			<div class="col-12 col-md-4 col-xl-5 text-center text-md-right order-md-3">
				<small>Webdesign <a href="https://www.tiagorio.com" class="text-primary" target="_blank">Tiago Rio</a></small>
			</div>
		</div>

	</footer>
</template>


<script>
/*
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
*/
export default {
	name: "main-footer",
	computed: {
		isContactos() {
			return this.$route.name === 'Contactos'
		}
	},
	mounted() { 
		this.$nextTick(function () {
			/* ------------------------------------- BT PEDIR ORÇAMENTO ------------------------------------- 
			const orcamento = this.$refs['pedirOrcamento'];
			gsap.set(orcamento, {y:150, autoAlpha:0});
			gsap.to(orcamento, 1, {
				scrollTrigger: {
					trigger: orcamento,
					start: "top bottom"
				},
				y:0, 
				autoAlpha:1
			});
			*/
		}) // END NEXT TICK
	}, // END MOUNTED
}
</script>
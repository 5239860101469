<template>
	<div id="inicio">

		<div class="top-spacer" v-if="dataReady==false">CENAS</div>

		<b-carousel id="carousel-1" 
			v-if="dataReady"
			v-model="slide" 
			:interval="4000"
			indicators
			img-width="1920" 
			img-height="1080"
			no-hover-pause="true"
			@sliding-start="onSlideStart"
			@sliding-end="onSlideEnd"
		>

			<b-carousel-slide 
				v-for="item in slideshow" 
				:key="item.value.title"
				:img-src="cockpitUrl+'/'+item.value.image.path"
				>
				<h4 class="text-line-1" v-html="firstWordBold(item.value.title)"></h4>
				<p class="text-line-2">{{ item.value.subtitle }}</p>
				<a :href="item.value.url" class="page-header-link btn btn-outline-primary mt10">Saiba mais</a>
			</b-carousel-slide>

		</b-carousel>

		<!--
		<p class="mt-4">
			Slide #: {{ slide }}<br>
			Sliding: {{ sliding }}
		</p>
		-->

		<div class="container mt100">

			<div class="row">
				<div id="#textIntro" class="col-12 col-xl-10 offset-xl-1 text-center" ref="introtext">
					<h1>Os nossos <b class="text-primary">Serviços</b></h1>
					<hr class="title-line">
					<h2>
						Fabricamos <b class="text-primary">marquises, estores, portas e janelas</b><br class="responsive-lg" />
						por medida. Seja para o exterior ou para o interior<br class="responsive-lg" />
						da sua casa, temos a solução que necessita.
					</h2>
				</div>	
			</div>


			<div class="row mt120">

				<div :id="'homeServico'+servico._o" class="col-12 col-sm-6 col-md-4 text-center home-servico" v-for="servico in $parent.servicosData" :key="servico._o" :ref="'homeServico'+servico._o" >
					<div :style="'background-image: url('+cockpitUrl+'/'+servico.home_image.path+');'">
						<div class="underlay"></div>
						<div class="overlay">
							<h3 v-html="firstWordBreak(servico.title)"></h3>
							<hr class="title-line-blue">
							<p>{{ servico.home_text }}</p>
							<router-link :to="'/servicos/'+servico._o+'/'+servico.title_slug" tag="button" class="btn btn-outline-primary">Saiba Mais</router-link>
						</div>
					</div>
				</div>	

			</div>

		</div><!-- /.container -->

		<MainFooter />

	</div>
</template>

<script>
import globals from '@/config/globals';
import axios from "axios";
//import {bus} from '@/main';
import MainFooter from "@/components/footer.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default { 
	name: "inicio",
	data() {
		return {
			dataReady: false,
			cockpitUrl: globals.BASE_URL,
			slideshow: null,
			slide: 0,
			sliding: null
		};
	},
	components: {
		MainFooter
	},
	methods: { 
		changeMetaData() {
			this.$parent.metaTitle = 'Início';
			this.$parent.metaDescription = 'Fabricamos estores, marquises, janelas e portas por medida';
		},
		firstWordBreak(content) {
			var textPre = content;
			var text = textPre.trim().split(" ");
			var first = text.shift();
			return first+"<br> " + text.join(" ");		
		},
		firstWordBold(content) {
			var text = content.trim().split(" ");
			var first = text.shift();
			return "<b>"+first+"</b> " + text.join(" ");
		},
		onSlideStart(slide) {
			console.log("Slide Start: "+slide);
			this.sliding = true
		},
		onSlideEnd(slide) {
			console.log("Slide End: "+slide);
			this.sliding = false
		},
		animatePage() {
			console.log("Animate Page");

			/* ------------------------------------- H1 ------------------------------------- */
			let txtintro = this.$refs['introtext'];
			gsap.set(txtintro, {y:150, autoAlpha:0});
			gsap.to(txtintro, 1, {
				scrollTrigger: {
					trigger: txtintro,
					start: "top bottom"
				},
				y:0,
				autoAlpha:1
			});

			/* ------------------------------------- DESTAQUES ------------------------------------- */
			gsap.set('#homeServico0', {x:300, autoAlpha:0});
			gsap.to('#homeServico0', 1, {
				scrollTrigger: {
					trigger: '#homeServico0',
					start: "top bottom"
				},
				ease: "power4.inOut",
				x:0,
				autoAlpha:1
			});

			gsap.set('#homeServico1', {x:300, autoAlpha:0});
			gsap.to('#homeServico1', 1, {
				scrollTrigger: {
					trigger: '#homeServico1',
					start: "top bottom"
				},
				ease: "power4.inOut",
				delay: 1,
				x:0,
				autoAlpha:1
			});

			gsap.set('#homeServico2', {x:300, autoAlpha:0});
			gsap.to('#homeServico2', 1, {
				scrollTrigger: {
					trigger: '#homeServico2',
					start: "top bottom"
				},
				ease: "power4.inOut",
				delay: 2,
				x:0,
				autoAlpha:1
			});

			gsap.set("#homeServico3", {x:300, autoAlpha:0});
			gsap.to("#homeServico3", 1, {
				scrollTrigger: {
					trigger: "#homeServico3",
					start: "top bottom"
				},
				ease: "power4.inOut",
				x:0,
				autoAlpha:1
			});

			gsap.set('#homeServico4', {x:300, autoAlpha:0});
			gsap.to('#homeServico4', 1, {
				scrollTrigger: {
					trigger: '#homeServico4',
					start: "top bottom"
				},
				ease: "power4.inOut",
				delay: 1,
				x:0,
				autoAlpha:1
			});

			gsap.set('#homeServico5', {x:300, autoAlpha:0});
			gsap.to('#homeServico5', 1, {
				scrollTrigger: {
					trigger: '#homeServico5',
					start: "top bottom"
				},
				ease: "power4.inOut",
				delay: 2,
				x:0,
				autoAlpha:1
			});
		}
	},
	mounted() { 
		axios
			.get(globals.COCKPIT_SERVER+'singletons/get/sliderhome'+globals.COCKPIT_TOKEN)
			.then(response => {
				this.slideshow = response.data.slider
				this.dataReady = true
				this.changeMetaData()
				console.log("Data received, start animation")
				//bus.$on('transition-done', this.animatePage)
				this.animatePage()
				
			});

		this.$nextTick(function () {
			console.log("nextTick")

		}) // END NEXT TICK

	}, // END MOUNTED
	updated: function () {
		
	}
} 
</script>
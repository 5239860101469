<template>
	<div id="app">

		<!-- Responsive Helper Tag to Identify Screen Sizes
		<div id="responsive-helper" style="color:#000; font-weight:bold; position:fixed; top:0px; right:0; z-index:12000;">
			<div class="d-block d-sm-none" style="background:turquoise; padding:2px 5px;">Xtra Small</div>
			<div class="d-none d-sm-block d-md-none" style="background:greenyellow; padding:2px 5px;">Small</div>
			<div class="d-none d-md-block d-lg-none" style="background:yellow; padding:2px 5px;">Medium</div>
			<div class="d-none d-lg-block d-xl-none" style="background:orange; padding:2px 5px;">Large</div>
			<div class="d-none d-xl-block" style="background:indianred; padding:2px 5px;">Xtra Large</div>
		</div>
		END Responsive Helper -->

		
		<nav class="navbar navbar-expand-lg navbar-dark">
			<div class="container">
			<div class="navbar-brand">
				<img src="./images/rcf-estores-logo.svg" style="height:70px;">
			</div>

			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav ml-auto">

					<li class="nav-item">
						<router-link to="/" class="nav-link">Início</router-link>
					</li>

					<li class="nav-item">
						<router-link to="/quemsomos" class="nav-link">Quem Somos</router-link>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Serviços
						</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdown">
							<router-link 
								v-for="servico in servicosData" 
								:key="servico._o" 
								:to="'/servicos/'+servico._o+'/'+servico.title_slug" 
								class="dropdown-item">
									{{ servico.title }}
							</router-link>
						</div>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Portfolio
						</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdown">
							<router-link 
								v-for="folio in portfolioData" 
								:key="folio._o" 
								:to="'/portfolio/'+folio.title_slug" 
								class="dropdown-item">
									{{ folio.title }}
							</router-link>
						</div>
					</li>

					<li class="nav-item">
						<router-link to="/contactos" class="nav-link">Contactos</router-link>
					</li>

				</ul>
			</div>
			</div>
		</nav>
		

		<transition name="fadeSlide" mode="out-in" @afterEnter="afterEnter">
			<router-view :key="$route.path" />
		</transition>

	</div>
</template>


<script>
import globals from '@/config/globals';
import axios from "axios";
import {bus} from '@/main';

/* DB CONNECTIONS */
const requestOne = axios.get(globals.COCKPIT_SERVER + "collections/get/servicos" + globals.COCKPIT_TOKEN + "&sort[_o]=1");
const requestTwo = axios.get(globals.COCKPIT_SERVER + "collections/get/portfolio" + globals.COCKPIT_TOKEN + "&sort[_o]=1");

export default {
	name: "App",
	data() {
		return {
			dataReady: false,
			servicosData: null,
			portfolioData: null,
			metaTitle: 'Estores, Marquises e Janelas por medida',
			metaDescription: 'Fabricamos estores, marquises, janelas e portas por medida',
			metaImage: globals.BASE_URL + '/images/share-image.jpg'
		};
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			titleTemplate: '%s | RCF Estores',
			meta: [
				{ hid:'description', name: 'description', content: this.metaDescription },
				{ hid:'og:site_name', property: 'og:site_name', content: 'RCF Estores' },
				{ hid:'og:title', property: 'og:title', content: this.metaTitle + ' | RCF Estores' },
				{ hid:'og:description', property: 'og:description', content: this.metaDescription },
				{ hid:'og:image', property: 'og:image', content: this.metaImage },
				{ hid:'og:url', property: 'og:url', content: globals.BASE_URL + this.$route.fullPath },
			] 
		}
	},
	mounted: async function () {
		await axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
			this.servicosData = responses[0].data.entries
			this.portfolioData = responses[1].data.entries
			this.dataReady = true
			bus.$emit('data-done');
		}))
	},
	methods: { 
		afterEnter() {
			console.log('transition-done');
			bus.$emit('transition-done');
		}
	}
};
</script>